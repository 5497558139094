/**
Synapse - adaptive theme
.col-xs- <544px Extra small
.col-sm- ≥544px Small
.col-md- ≥768px Medium
.col-lg- ≥992px Large
.col-xl- ≥1200px Extra large
Gutter width	1.875rem / 30px (15px on each side of a column)
*/
/** Map */
#synmap {
  height: 450px;
  width: 100%;
}

#main {
  background-color: #F8F8F8;
}

.synmap {
  min-height: 200px;
}

/** Прикреплённая галерея */
.node .field--name-field-gallery {}

.node .field--name-field-gallery:after {
  content: "";
  display: table;
  clear: both;
}

.node .field--name-field-gallery .field__item {
  float: left;
  margin: 0 15px 15px 0;
}

/** contact **/
#block-contact-img {
  margin-top: 20px;
}

/** gallery **/
.view-id-images.view-display-id-page {
  .row>div {
    margin-bottom: 20px;
  }

  .views-field-field-gallery {
    text-align: center;

    img {
      border: 1px solid #c0c0c0;
      padding: 3px;
    }
  }

  .views-field-title {
    text-align: center;
    margin: 10px 0 0;

    a {
      position: relative;
      padding: 0 20px;

      &:before {
        content: "";
        background: url("../images/arrow.png") no-repeat 0 0;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
      }
    }
  }
}

.node--type-image.node--view-mode-full {
  .field--name-field-gallery img {
    border: 1px solid #c0c0c0;
    padding: 3px;
  }
}

#block-katalog,
#block-katalog--2 {
  background: #fff;
  margin-bottom: 20px;

  ul {
    margin: 0;
  }

  a {
    display: block;
    padding: 18px 15px;
    font-family: 'pf_din_text_cond_proregular';
    font-size: 20px;
    line-height: 20px;
    color: #008791;
    text-decoration: underline;

    &.is-active,
    &:focus,
    &:hover {
      background: #008791;
      color: #fff !important;
      text-decoration: none;
      -webkit-transition: all 200ms linear;
      -moz-transition: all 200ms linear;
      -ms-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      transition: all 200ms linear;
    }
  }
}

/** catalog **/
.view-id-taxonomy_term {
  .feed-icons {
    display: none;
  }
}

.node--type-tovar.node--view-mode-teaser {
  background: #fff;
  padding: 30px 25px;
  margin-bottom: 15px;

  h2 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 13px;

    a {
      border-bottom: 2px solid #86b9bf;
      line-height: 23px;

      &:focus,
      &:hover {
        text-decoration: none;
        border-color: transparent;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }
    }
  }

  .node__content {
    font-size: 18px;
    line-height: 23px;

    .img {
      overflow: hidden;

      a {
        display: block;
        overflow: hidden;
        float: left;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      -webkit-transition: all 200ms linear;
      -moz-transition: all 200ms linear;
      -ms-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      transition: all 200ms linear;

      &:hover {
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }
    }

    .price {
      .value {
        font-size: 22px;
        font-family: 'pf_din_text_cond_proregular';
        font-weight: bold;

        sup {
          top: 0.5em;
        }
      }

      .field--name-field-tovar-price {
        margin-right: 7px;
      }
    }

    .desc {
      margin: 10px 0;
    }

    .btn {
      border: 0;
      background: #008791;
      color: #fff;
      font-family: 'pf_din_text_cond_proregular';
      font-weight: bold;
      font-size: 20px;
      padding: 13px 40px;
      margin: 16px 0 0;
      border-radius: 5px;

      &:focus,
      &:hover {
        box-shadow: none;
        background: #0d9ea9;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }
    }
  }

  @media (min-width: 992px) {
    .node__content {
      flex-direction: row;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      .value {
        text-align: center;
      }

      .img,
      .price {
        width: 20%;
      }

      .desc {
        padding: 0 25px;
        flex-basis: 60%;
      }
    }
  }
}

#block-characteristic,
#block-characteristic--2 {
  background: url("../images/gray-bg.png") repeat 0 0;
  padding: 30px 0 15px;

  h2 {
    text-align: center;
    color: #008791;
    font-size: 35px;
    font-family: 'pf_din_text_cond_proregular';
    font-weight: bold;
    margin: 0 0 20px;
  }

  .container {
    padding: 0;
    flex-direction: row;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;

    &>div {
      text-align: center;
      padding: 0 10px;
      width: 100%;
      margin-bottom: 20px;
    }

    @media (min-width: 768px) {
      &>div {
        flex-basis: 20%;
      }
    }

    p {
      margin: 2px 0 0;
      color: #333333;
      font-size: 17px;
      line-height: 19px;
      font-family: 'pf_din_text_cond_proregular';
    }
  }
}

#block-catalog-text,
#block-catalog-text--2,
.block-views-blockcatalog-block-catalog-description {
  background: #fff;
  padding: 65px 0 40px;
  font-size: 18px;
  line-height: 25px;

  h2 {
    font-size: 36px;
    margin: 0 0 25px;
  }

  &>h2 {
    display: none;
  }
}

.contact-message-order-form {
  .form-control {
    color: #008791;
  }
}

.contact-message-order-project-form {
  background: url("../images/gray-bg.png") repeat 0 0;
  padding: 70px 0 40px;

  h2 {
    text-align: center;
    color: #008791;
    font-size: 36px;
    font-family: 'pf_din_text_cond_proregular';
    font-weight: bold;
    padding: 0 15px;
    margin: 0 0 20px;
  }

  p {
    text-align: center;
    font-size: 18px;
  }

  form {
    max-width: 790px;
    margin: 0 auto;

    label {
      display: none;
    }

    .form-control {
      padding: 13px 15px;
      color: #008791;
      border-color: #dddddd;

      &:focus {
        border-color: #008791;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }
    }

    textarea {
      padding: 13px 15px;
      border-color: #dddddd;
      height: 175px;
      resize: none;

      &:focus {
        border-color: #008791;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }
    }

    .form-actions {
      text-align: right;

      input {
        border: 0;
        background: #008791;
        color: #fff;
        font-family: 'pf_din_text_cond_proregular';
        font-weight: bold;
        font-size: 20px;
        padding: 13px 35px;
        border-radius: 5px;

        &:focus,
        &:hover {
          box-shadow: none;
          background: #0d9ea9;
          -webkit-transition: all 200ms linear;
          -moz-transition: all 200ms linear;
          -ms-transition: all 200ms linear;
          -o-transition: all 200ms linear;
          transition: all 200ms linear;
        }
      }
    }
  }

  ::-webkit-input-placeholder {
    color: #008791;
  }

  ::-moz-placeholder {
    color: #008791;
  }

  :-moz-placeholder {
    color: #008791;
  }

  :-ms-input-placeholder {
    color: #008791;
  }
}

/** tovar page **/
.node--type-tovar .region-title {
  display: none;
}

.node--type-tovar.node--view-mode-full {
  .row {
    margin: 0;

    &>div {
      padding: 0;
    }
  }

  .node__content>h2 {
    font-size: 38px;
    margin-bottom: 20px;
  }

  .field--name-field-gallery .field__item {
    float: none;
  }

  .field--name-field-gallery {
    float: left;
    margin-right: 30px;
  }
}

.path-node.node--type-tovar {
  #block-adaptive-content {
    background: #fff;
    padding: 35px 30px;
  }
}

.node--type-tovar.node--view-mode-full {
  .desc {
    overflow: hidden;
    width: 37%;

    .field {
      background: #f5f5f5;
      border-bottom: 1px solid #fff;
      padding: 13px 11px;
    }

    button {
      margin-top: 20px;
    }
  }

  .tovar-img {
    width: 60%;
    margin-right: 3%;
    float: left;
    overflow: hidden;

    img {
      max-width: 100%;
      height: auto;
    }

    .image-big {
      margin-right: 1%;
    }

    .image-small {
      img {
        cursor: pointer;
      }

      .field--name-field-gallery {
        flex-direction: row;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .field__item {
          margin: 5px 1% 5px 0;
          width: 24%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .tovar-img {
      width: 100%;
    }

    .desc {
      width: 99%;
    }
  }

  #tovar-tabs {
    clear: both;
    padding: 45px 0 0;
    margin-left: -5px;

    .nav {
      flex-wrap: wrap;
    }

    li.nav-item {
      margin-left: 5px;
    }

    a.nav-link {
      background: #e0e0e0;
      color: #008791;
      font-size: 19px;
      padding: 15px;
      cursor: pointer;
      border-radius: 4px 4px 0 0;

      &.active,
      &:hover {
        background: #f5f5f5 !important;
        color: #333333 !important;
      }
    }

    .tab-content {
      background: #f5f5f5;
      padding: 20px 25px 10px;
      font-size: 18px;
      line-height: 23px;
      position: relative;
      top: -1px;
      margin-left: 5px;
    }
  }

  .back-catalog {
    font-size: 20px;
    font-weight: bold;
    margin: 40px 0 0;

    a:hover {
      text-decoration: none;
    }

    a span {
      border-bottom: 2px solid;
      line-height: 18px;
      display: inline-block;

      &:hover {
        border-color: transparent;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }
    }
  }

  .block-right {
    padding: 20px 0;
    clear: both;
  }

  @media (min-width: 992px) {
    .block-right {
      margin-left: 20px;
      padding: 0;
    }
  }

  @media (min-width: 1200px) {
    .block-right {
      margin-left: 60px;
      padding: 0;
    }
  }

  .text-info {
    background: url("../images/info-icons.png") no-repeat 0 center;
    padding-left: 36px;
    color: #008791 !important;
    font-size: 18px;
    line-height: 20px;
    max-width: 250px;
  }

  .field-collection-item--name-field-tovar-colors,
  .paragraph--type--tovar-colors {
    overflow: hidden;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 20px;
    margin-bottom: 20px;
    line-height: 20px;

    .content>div {
      overflow: hidden;
    }

    .field--name-field-tovar-colors-img {
      float: left;
      margin-right: 15px;
    }

    .field--name-field-field-tovar-colors-price,
    .field--name-field-tovar-colors-price {
      font-size: 24px;
      line-height: 24px;
      font-weight: bold;
    }
  }
}

/** front **/
.front #title-page .region-title {
  // text-indent: -9999px;
  overflow: hidden;

  // height: 0px;
  h1 {
    margin: 2rem 0 1rem;
    color: #017e86;
    text-align: center;
  }
}

// .view-id-slide.view-display-id-block {
//   visibility: hidden;
//   text-align: center;
//   max-width: 855px;
//   margin: 30px auto 0;
//   .views-field-field-slide-image {
//     text-align: center;
//   }
//   img {
//     width: auto !important;
//     max-width: 100% !important;
//     height: auto;
//   }
//   .owl-next,
//   .owl-prev {
//     width: 42px;
//     height: 42px;
//     text-indent: -9999px;
//     overflow: hidden;
//     position: absolute;
//     top: 50%;
//     margin-top: -21px;
//     &:hover {
//       opacity: 0.7;
//       -webkit-transition: all 200ms linear;
//       -moz-transition: all 200ms linear;
//       -ms-transition: all 200ms linear;
//       -o-transition: all 200ms linear;
//       transition: all 200ms linear;
//     }
//   }
//   .owl-prev {
//     background: url("../images/prev.png") no-repeat 0 0;
//     left: 40px;
//   }
//   .owl-next {
//     background: url("../images/next.png") no-repeat 0 0;
//     right: 40px;
//   }
// }
.front {
  #order {
    background: #fff;
  }

  &-promo {
    position: absolute;
    color: #333333;
    z-index: 1;
    transform: translateY(-50%);

    @media (max-width: 991px) {
      margin-top: 50px;
      position: relative;
      transform: none;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 0 0 -13px;

      @media (max-width: 991px) {
        margin: 0 -13px;
      }
    }

    &-item {
      width: 25%;
      min-height: 100%;
      text-align: center;
      padding: 0 13px;

      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 26px 25px;
        background-color: #fff;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
      }

      @media (max-width: 991px) {
        width: 50%;

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 15px;
        }
      }

      @media (max-width: 526px) {
        width: 100%;
        margin-bottom: 15px;
      }

      &--img {
        flex-shrink: 0;
        margin-bottom: 7px;
        // svg {
        //   width: 36px;
        //   height: 36px;
        // }
      }

      &--text {
        font-family: 'PFDinTextPro';
        font-weight: normal;
        font-size: 22px;
        line-height: 1;
      }
    }

    &-bottom {
      text-align: right;

      @media (min-width: 1200px) {
        text-align: center;
      }
    }

    &-img {
      max-width: 100%;
      margin-bottom: -75px;

      @media (min-width: 1200px) {
        margin-left: 140px;
        margin-bottom: -198px;
      }
    }
  }
}

#block-front-block,
#block-front-block--2 {
  text-align: center;
  background: #fff;
  padding: 50px 0;

  h2 {
    padding: 0 15px;
    color: #017e86;
    text-align: center;
  }

  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
}

.contact-message-online-order-form {
  h2 {
    color: #017e86;
    text-align: center;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  form label {
    float: left;
    width: 100px;
  }

  input.form-control {
    width: auto;
    max-width: 100%;
  }
}

.node--type-article {
  .content-article {
    margin-bottom: 1rem;
    border-bottom: 2px solid #000;

    .node__content {
      margin-top: 1rem;

      .link-article {
        margin: 1rem 0;
        display: block;
        font-size: 1.3rem;
        padding: 0;
      }
    }
  }
}

#block-tovarynaglavnoi {
  .sidewalkTile {
    background-color: #fff;
    margin: 5rem auto 4.2rem;
    padding: 2rem 1rem;

    .sidewalkTile-text {
      padding-left: 25px;
      padding-right: 55px;
      margin-bottom: 1rem;

      h2 {
        font-size: 39px;
        margin-bottom: 1.2rem;

        a {
          text-decoration: underline;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.2;
      }
    }

    .link-catalog {
      background: #008791;
      color: #fff;
      padding: 0.8rem 2.5rem;
      border-radius: 7px;
      margin-right: 1.2rem;
      font-size: 20px;
    }

    .link-catalog:hover {
      color: #fff !important;
    }
  }

  .goods-title {
    margin-left: 1.6rem;
    font-size: 2.2rem;
  }

  .goods {
    margin-top: 1.4rem;

    .goods-item {
      &-bg {
        background: #fff;
        padding: 1.5rem 1rem;
        height: 100%;
      }

      &-img {
        text-align: center;
      }

      h2 {
        font-size: 1.6rem;
        margin-top: 1.8rem;
        padding-left: 0.4rem;
        padding-right: 2rem;

        a {
          text-decoration: underline;
        }
      }

      p {
        font-size: 1.1rem;
        padding-left: 0.4rem;
      }
    }
  }
}

.page {
  &-title {
    &--front {
      font-family: 'PFDinTextCondPro';
      font-weight: bold;
      padding-left: 34px;
      line-height: 1;
      position: relative;

      &::before {
        position: absolute;
        width: 133px;
        height: 10px;
        background: #008791;
        bottom: -25px;
        left: 0;
        content: '';
      }

      @media (min-width: 1024px) {
        font-size: 75px;
        max-width: 80%;
      }

      @media (max-width: 526px) {
        padding-left: 0;
        letter-spacing: 0.3px;
      }
    }
  }
}

#block-seotekst {
  background: #fff;
  padding: 50px 0;

  h3 {
    color: #017e86;
  }
}

.service-slider {
  .service-promo {
    width: 100%;
    color: #FFF;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(#3f3f3f, 0.43);

    &-button {
      display: inline-block;
      padding: 16px 52px;
      color: #fff;
      font-size: 20px;
      margin-top: 30px;
      text-transform: uppercase;
      background-color: #008791;

      &:hover {
        color: #fff !important;
        text-decoration: underline;
      }
    }

    &-content {
      color: #FFFFFF;
      padding: 148px 0 75px;
      min-height: 610px;
      background-color: rgba(#000, 0.4);

      @media (min-width:575) {
        padding: 52px 0 40px;
        min-height: 250px;
      }
    }

    &-title {
      font-size: 52px;
      line-height: 1.2;
      font-weight: 700;
      margin: 0 0 20px;
      max-width: 794px;
      letter-spacing: 0.3px;
      font-family: 'PFDinTextCondPro';

      @media (max-width: 575px) {
        font-size: 36px;
        margin-bottom: 20px;
      }
    }

    &-short {
      max-width: 680px;
      font-size: 22px;
      line-height: 1.55;
      font-weight: 400;
      margin: 0 0 15px;

      @media (max-width: 992px) {
        font-size: 26px;
      }

      @media (max-width: 575px) {
        font-size: 22px;
        line-height: 1.4;
        margin-bottom: 30px;
      }
    }

    &-info {
      h3 {
        font-size: 22px;
        line-height: 1.4;
        font-weight: 300;
        letter-spacing: 0.1px;

        @media (max-width: 575px) {
          font-size: 21px;
          line-height: 1.3;
        }
      }
    }
  }

  .service-promo--slide {
    position: relative;
    width: 100%;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .service-promo--slide .service-promo-title {
    @media (max-width:762) {
      font-size: 35px;
    }
  }

  .service-promo--slide .service-promo-short {
    font-weight: 400;
    line-height: 1.55;
    margin-bottom: 10px;

    @media (min-width:992) {
      font-size: 22px;
    }

    @media (max-width:762) {
      font-size: 20px;
    }
  }

  .service-promo--slide .service-promo-image {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .service-promo--slide .service-promo-content {
    position: relative;
    z-index: 2;
  }
}

.view-tovar {
  position: relative;
  max-width: 942px;
  width: 100%;
  padding-top: 64px;
  z-index: 2;

  @media screen and(max-width: 1199px) {
    padding-top: 20px;
  }

  .view-content {
    display: grid;
    grid-template-columns: repeat(3, minmax(296px, 296px));
    gap: 27px;
    width: 100%;

    &.flickity-enabled {
      display: block;
      width: 100%;

      .views-row {
        width: 296px;
        margin-right: 27px;
      }
    }
  }

  .node-token {
    &__img {
      max-width: 296px;
      height: 239px;

      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__desc {
      padding: 30px 20px 22px 25px;
      background-color: #fff;
      min-height: 199px;
      display: flex;
      flex-direction: column;
    }

    &__color {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #494949;
      margin-bottom: 10px;

      span {
        margin-right: 5px;
      }
    }

    &__size {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #494949;

      span {
        margin-right: 5px;
      }
    }

    h2 {
      margin-bottom: 12px;

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 1;
        color: #008791;
        display: block;
      }
    }

    &__price {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #494949;
      margin-top: auto;
    }
  }
}

.block-goods {
  padding-top: 104px;

  // overflow: hidden;
  @media screen and (max-width: 1199px) {
    padding-top: 50px;
  }

  &__grid {
    display: flex;
    justify-content: space-between;

    @media screen and(max-width: 1199px) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__title {
    width: calc(100% - 942px);

    @media screen and(max-width: 1199px) {
      width: 100%;
    }

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 1;
      color: #333;
      margin-top: 96px;
      position: relative;
      z-index: 1;
      margin-bottom: 100px;

      &::before {
        content: url(../images/lider-img.jpg);
        position: absolute;
        top: -96px;
        right: -96px;
        width: 671px;
        height: 351px;
        z-index: -1;

        @media screen and(max-width: 1199px) {
          top: -20px;
        }
      }

      @media screen and(max-width: 1199px) {
        margin: 20px 0;
      }
    }

    &+div {
      width: 100%;
      flex: 1;
    }
  }

  &__control {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    @media screen and(max-width: 1199px) {
      justify-content: center;
    }

    .carousel-status {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 1.2;
      color: #494949;
      margin: 0 15px;
      width: 62px;
      text-align: center;
    }

    .carousel-button {
      cursor: pointer;
      width: 12px;
      height: 18px;
      transition: 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.block-catalog-front {
  padding-top: 185px;
  text-align: center;

  @media screen and (max-width: 1199px) {
    padding-top: 50px;
  }

  h2 {
    margin-bottom: 48px;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: #494949;
  }

  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .views-row {
    display: flex;
    align-items: center;
    max-width: 531px;
    min-height: 221px;
    background-color: #ECECEA;
    margin-bottom: 36px;
    height: auto;
    width: 100%;

    .vocabulary-catalog {
      width: 100%;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      h2 {
        margin: 0 23px 0 0;
        font-weight: 500;
        font-size: 30px;
        line-height: 1.2;
        color: #494949;
        max-width: 184px;
        text-align: left;

        @media screen and (max-width: 576px) {
          max-width: 100%;
          margin: 0 0 10px;
        }
      }
    }
  }
}
